import strapiLogo from "../../images/techno-logo/strapi.png"
import { motion } from "framer-motion"
import React from "react"

export const TechCard = ({ name = "js", logo = strapiLogo, variants }) => {
  return (
    <motion.div
      className="flex h-24 w-full items-center justify-center rounded border bg-eido-grey-500 border-eido-grey-900"
      variants={variants}
    >
      <div className="flex flex-col items-center text-white">
        <img src={logo} className="h-10 w-10 object-contain" alt={`${name} logo`} />
        <div className="mt-2 text-xs">{name}</div>
      </div>
    </motion.div>
  )
}
