import React from "react"

//component
import { TechCard } from "./techCard"

// logos
import jsLogo from "../../images/techno-logo/js.png"
import strapiLogo from "../../images/techno-logo/strapi.png"
import tailwindLogo from "../../images/techno-logo/tailwindcss.png"
import cssLogo from "../../images/techno-logo/css.png"
import jekyllLogo from "../../images/techno-logo/jekyll.png"
import sassLogo from "../../images/techno-logo/sass.png"
import netlifyLogo from "../../images/techno-logo/netlify.png"
import gatsbyLogo from "../../images/techno-logo/gatsby.png"
import cloudflareLogo from "../../images/techno-logo/cloudflare.png"
import digitaloceanLogo from "../../images/techno-logo/digitalocean.png"
import reactLogo from "../../images/techno-logo/react.png"

const Technos = ({ technos, variants }) => {
  const techTree = {
    js: {
      name: "javascript",
      logo: jsLogo,
    },
    tailwindcss: {
      name: "tailwindcss",
      logo: tailwindLogo,
    },
    strapi: {
      name: "strapi",
      logo: strapiLogo,
    },
    css: {
      name: "css",
      logo: cssLogo,
    },
    jekyll: {
      name: "Jekyll",
      logo: jekyllLogo,
    },
    sass: {
      name: "sass",
      logo: sassLogo,
    },
    netlify: {
      name: "netlify",
      logo: netlifyLogo,
    },
    cloudflare: {
      name: "cloudflare",
      logo: cloudflareLogo,
    },
    gatsby: {
      name: "gatsby",
      logo: gatsbyLogo,
    },
    digitalocean: {
      name: "digitalocean",
      logo: digitaloceanLogo,
    },
    react: {
      name:"react",
      logo: reactLogo,
    }
  }

  return technos.map(tech => {
    if (techTree[tech]) {
      return (
        <TechCard
          key={techTree[tech].name}
          name={techTree[tech].name}
          logo={techTree[tech].logo}
          variants={variants}
        />
      )
    }
    return null
  })
}

export default Technos
