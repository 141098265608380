import React, { useRef } from "react"
import Technos from "./Technos"
import { motion, useInView } from "framer-motion"

const ProjetInfoCard = ({ technos }) => {
  const container = useRef(null)
  const isInView = useInView(container, { amount: .75 })
  const containerAni = {
    hidden: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1
      }
    },
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  const logoContainerAni = {
    hidden: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1
      }
    },
    show: {
      transition: {
        staggerChildren: 0.1,
        ease: [0.16, 1, 0.3, 1],
      },
    },
  }

  const itemAni = {
    hidden: { x: -15, opacity: 0, filter: "blur(1px)" },
    show: {
      x: 0,
      opacity: 1,
      filter: "blur(0px)",
      transition: {
        duration: 0.6,
        staggerChildren: 0.1,
        ease: [0.16, 1, 0.3, 1],
      },
    },
  }

  return (
    <motion.div
      ref={container}
      initial="hidden"
      animate={isInView ? "show" : "hidden"}
      variants={containerAni}
      className="overflow:hidden rounded-lg border border-eido-grey-100 bg-eido-grey-200 py-10 px-6 text-lg dark:border-eido-grey-900 dark:bg-eido-grey-700 md:text-xl"
    >
      <div>
        <motion.h2
          className="titre font-metropolis font-bold uppercase"
          variants={itemAni}
        >
          Technologies
        </motion.h2>
        <motion.p className="mt-4 md:text-lg" variants={itemAni}>
          Une liste non exhaustive des technologies utilisées dans ce projet
        </motion.p>
      </div>
      <motion.div
        className="mt-4 flex grid grid-cols-3 lg:grid-cols-4 gap-4"
        variants={logoContainerAni}
      >
        <Technos technos={technos} variants={itemAni} />
      </motion.div>
    </motion.div>
  )
}

export default ProjetInfoCard
