import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { motion } from "framer-motion"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import ProjetInfoCard from "../components/ProjetInfoCard"
import Technos from "../components/Technos"

const AnimatedLetters = ({ title, disabled = false }) => {
  const banner = {
    animate: {
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.1,
      },
    },
  }

  const letterAni = {
    initial: { y: 400 },
    animate: {
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  }

  return (
    <motion.span
      className="row-title"
      variants={disabled ? null : banner}
      initial="initial"
      animate="animate"
    >
      {[...title].map(letter => (
        <motion.span
          className="row-letter inline-block"
          variants={disabled ? null : letterAni}
        >
          {letter === " " && <span>&nbsp;&nbsp;</span>}
          {letter !== " " && letter}
        </motion.span>
      ))}
    </motion.span>
  )
}

const Template = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const imageData = getImage(frontmatter.image)

  return (
    <>
      <header className="relative">
        <div className="absolute top-0 left-0 w-full text-6xl font-bold opacity-10 md:text-9xl">
          {frontmatter.technos && (
            <div className="marquee text-[30rem]">
              <div className="marquee__content">
                {frontmatter.technos.map(techno => {
                  return (
                    <span key={techno} className="mr-12">
                      {techno}
                    </span>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        <div className="container mx-auto px-2">
          <h1 className="line-mask mask-colors relative z-10 mt-10 md:mt-20 font-metropolis text-5xl font-bold font-black tracking-tight dark:text-eido-grey-100 md:text-9xl">
            {frontmatter.title}
          </h1>
        </div>
      </header>
      <section className="container mx-auto pt-12">
        <motion.div
          className="group relative aspect-[1920/1080] w-full"
          layoutId={frontmatter.slug}
        >
          {1 === 1 ? (
            <img
              className="w-full rounded-2xl"
              src={frontmatter.image.publicURL}
              alt={frontmatter.title}
            />
          ) : (
            <GatsbyImage
              className="mask h-full w-full"
              objectFit={"cover"}
              image={imageData}
              alt={frontmatter.title}
            />
          )}
          {frontmatter.site && (
            <>
              <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-black text-white opacity-0 transition-all group-hover:opacity-75">
                Visiter le site
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="ml-4 h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                  />
                </svg>
              </div>

              <Link to={frontmatter.site} className="absolute inset-0" />
            </>
          )}
        </motion.div>
      </section>
      <section className="container mx-auto">
        <div className="my-12 md:my-24">
          <div className="grid gap-6 px-4  md:px-2 md:grid-cols-5">
            <div className="information-container md:col-span-2 ">
              {frontmatter.technos && (
                <ProjetInfoCard technos={frontmatter.technos} />
              )}
            </div>
            <div className="md:col-span-3">
              <div
                className="prose prose-lg font-metropolis text-eido-grey-900 dark:text-eido-grey-200 md:prose-2xl"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export const Head = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const technosString = frontmatter.technos
    ? frontmatter.technos.join(" + ")
    : null
  console.log(technosString)
  const url = `https://jazzy-selkie-8fe18d.netlify.app/templates/eidolon-template/images/open-graph.jpg?title=${encodeURI(
    frontmatter.title
  )}&categorie=${encodeURI("Réalisation")}&technos=${encodeURI(
    technosString
  )}&mainImageUrl=${encodeURI(data.site.siteMetadata.siteUrl)}${
    frontmatter.image.publicURL
  }`

  return (
    <Seo
      title={frontmatter.title}
      description={markdownRemark.excerpt}
      imageURL={url}
      canonical={`https://eidolon.design${frontmatter.slug}`}
    />
  )
}

export default Template

export const pageQuery = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      excerpt
      html
      frontmatter {
        slug
        site
        title
        technos
        isSoon
        image {
          id
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        seo {
          description
          title
        }
      }
    }
  }
`
